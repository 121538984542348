/**
* Team Section Style
* Created by Emmanuella Albuquerque on 2023/03/13.
*/

.team h1 {
  text-align: center;
  font-weight: 700;
  font-size: 64px;
  color: #003C82;

  margin: 0;
}

.members-container {
  display: flex;
  flex-direction: row;

  background-color: #003C82;

  justify-content: center;

  padding-top: 63px;
  padding-bottom: 63px;
}

.members-container h2 {
  color: #fff;
  font-weight: 700;
  font-size: 48px;
}

.divider {
  border-left: 5px solid #D9D9D9;
  height: 437px;

  margin-top: 3rem;
}

.coordinator_container {
  margin-right: 128px;

  text-align: center;
}

.devs_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.devs_container h2 {
  /* Centraliza o título "Devs" */
  text-align: center;
  width: 100%;
}

.devs_container .avatar_group {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; /* Quebra a linha quando não cabe mais avatares */
  gap: 2rem; /* Espaçamento entre os avatares */
}

.Avatar {
  margin: 0 auto;
}


.image_wrapper_top {
  width: 100%;
  height: 100px;

  overflow: hidden;
  position: relative;
}

.image_wrapper_top img {
  position: absolute;
  transform: rotate(23.78deg);
  top: 10%;
}

.image_wrapper_bottom {
  width: 100%;
  height: 147px;

  overflow: hidden;
  position: relative;
}

.image_wrapper_bottom img {
  position: absolute;
  transform: rotate(23.78deg);

  right: 0;
  bottom: 15%;
}

/* MOBILE Team Section */
@media only screen and (max-width: 1080px) {
  .divider {
    height: 100vh;
  }

  
  .coordinator_container img{
    width: 10em;
  }

  .members-container h2 {
    color: #fff;
    font-weight: 700;
    font-size: 33px;
}

.devs_container {
  grid-template-columns: 1fr; /* Uma coluna no mobile */
  justify-items: center;
  align-items: center;
}

.image_wrapper_bottom img{
  width: 12em;
  
}
.image_wrapper_top img{
  width: 12em;
  
}
}

@media only screen and (max-width: 800px) {
  .devs_container {
    margin-left: 10px;
  }

  .coordinator_container {
    margin-right: 10px;
  } 

  .members-container {
    flex-direction: column;
  }

  .divider {
    border: 0;
    border-top: 5px solid #D9D9D9;

    height: 10px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .devs_container {
    width: 90%;
    grid-gap: 1rem;
  }
}
