/* DEFAULT FONT */
body, body a {
  color: #003C82;
  font-family: 'Inter', sans-serif;
}

body {
  /* display: flex;
  justify-content: center; */
}

.container70 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-smarthphone{
  width: 20em;
}
