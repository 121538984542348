/**
* Header Style
* Created by Emmanuella Albuquerque on 2023/03/12.
*/

header {
  justify-content: space-around;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 2em;
  position: relative;
}

header, nav ul, .logo-container {
  display: flex;
}

nav {
  display: flex;
  flex-direction: column;
  top: 100%; /* Position directly below the header */
  left: 0;
  transform: translateY(-100%);
  transition: transform 1s ease-out, opacity 1s ease-out; /* Slower transition */
  opacity: 0;
  visibility: hidden;
}

nav.visible {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}


nav.active {
  display: block;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  text-decoration: none;
  margin-right: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 200px;
  transition: margin 0.5s ease; /* Adiciona transição suave para movimento de margem */
}

.logo-container h1 {
  margin-left: 30px;
  cursor: pointer; /* Indica que o H1 é clicável */
  transition: transform 0.5s ease-out; /* Adiciona transição suave para transformação */
}

.logo-container img {
  width: 8em;
  height: 8em;
}

header nav {
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 24px;
}

header h1 {
  font-weight: 600;
  font-size: 40px;
  color: #003C82;
}


.my-custom-modal .modal-dialog {
  max-width: 80%!important; 
 
}

.my-custom-modal .modal-content {
  height: 100%;
}
.my-custom-modal .modal-bodyy {
  padding: 5em;
}
.my-custom-modal .my-table {
  text-align: center;
  vertical-align: middle;
}



/* MOBILE HEADER */
@media only screen and (max-width: 500px) {
  header h1 {
    font-size: 30px;
  }

  .logo-container {
    display: grid;
    place-items: center;
    margin-right: 0px;
  }

  .logo-container, .logo-container h1 {
    margin-left: 0px;
  }

  .logo-container h1 {
    margin-top: 0px;
  }


  
}

@media only screen and (max-width: 1080px) {
  header nav {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .logo-container {
    margin-right: 0;
  }
}