.section-container {
  display: flex;
  text-align: justify;
  justify-content: space-between;
  align-items: center;

  margin-top: 175px;

  margin-left: 20px;
}

.section-container h1 {
  font-weight: 600;
  font-size: 48px;
  color: #003C82;
}

.section-container p {
  font-size: 32px;
  color: #646464;
  width: 669px;
}

.section-container img {
  width: 274px;
  height: 274px;
}

.section-container a {
  word-break: break-word;
}

.right-text-container {
  margin-right: 200px;
}

.left-text-container {
  margin-left: 200px;
}

/* MOBILE Section */
@media only screen and (max-width: 1080px) {
  .section-container {
    flex-direction: column;
  }

  .section-container p {
    width: 70%;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right-text-container {
    margin-right: 0px;
  }
  
  .left-text-container {
    margin-left: 0px;
  }

  .section-container img{
    display: none;
  }

  .section-container{
    margin-top: 2em;
  }


  
}

@media only screen and (max-width: 500px) {
  .section-container h1 {
    font-size: 30px;
  }

  .section-container p {
    font-size: 20px;
  }

  .section-container img {
    width: 174px;
    height: 174px;
  }
 
}
