.ufpb {}

.cnpq {}

.chemaxon {}

.sistematx {}

.team .partners {
  padding-bottom: 5em;
}

.partners-container {
  display: grid;
  place-items: center;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  margin: 0 auto;
  padding-bottom: 10em;

}

.partners h1 {
  margin-bottom: 75px;
  margin-top: 75px;
}

/* MOBILE Team Section */
@media only screen and (max-width: 1080px) {
  .partners-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  .ufpb {
    height: 50%;
  }

  .cnpq {
    height: 50%;
  }

  .chemaxon {
    height: 50%;
  }

  .sistematx {
    height: 50%;
  }

  .partners-container {
    display: grid;
    place-items: center;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 10em;
  }
}