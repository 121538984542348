/**
* Sidebar Component
* Created by Emmanuella Albuquerque on 2023/03/12.
*/

.bm-item {
  color: #003C82;
  text-align: center;

  margin-bottom: 10px;
  text-decoration: none;
  padding: 1rem;
}

nav ul li a{
  color: #003C82;
  transition: all 0.5s;
}
nav ul li a:hover{
  color: rgba(45, 32, 161, 0.8);
 
}



/* MOBILE HEADER */
@media only screen and (min-width: 1080px) {
  .bm-burger-button {
    display: none;
  }
}
