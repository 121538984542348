.container-footer{
    background-color: #003C82;
}
.container-footer .org-info img{
  width: 15em;
  height: 15em;
}
.container-footer .org-info h3{
    color: #fff;
    font-size: 20pt;
}
.container-footer .org-info p{
    color: #fff;
    font-size: 18pt;
    margin-block-start: 0.5em;
    margin-block-end: 0em;

}
.container-footer .info-img{
    width: 35%;
    display: grid;
    text-align: center;
}
.container-footer .info-img h3{
    font-size: 34pt;
    margin-block-start: -0.2em;
    margin-block-end: 0em;
}
.container-footer .org-info {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    padding-top: 5em;

    width: 70%;
    margin: 0 auto;
}

hr{
    width: 90%;
    margin-top: 4em;
}

.info-reserved p{

    color: #fff;
    text-align: center;
    margin-top: 2em;
    margin-bottom: 0;
    padding-bottom: 3rem;
 
    font-size: 16pt;
}

.info-link p {
    cursor: pointer;
    color: #fff;
    transition: all 0.5s;
}
.info-link p:hover {
    color: #003C82;
}

@media only screen and (max-width: 768px) {
    .container-footer .org-info {
        grid-template-columns: 1fr;
        gap: 2em;
        padding-top: 3em;
    }
    
    .container-footer .info-img {
        width: 100%;
        justify-items: center;
    }

    .info-reserved p {
        color: #fff;
        text-align: center;
        margin-top: 2em;
        margin-bottom: 0;
        padding-bottom: 3rem;
        font-size: 11pt;
    }
}
